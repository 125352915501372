@import 'https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@300;400&display=swap';



.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 40%;
  
  font-family: 'Source Sans 3', sans-serif;
  font-size: 6.5cqw;
  line-height: 6.5cqw;
  color: #ecf0f1;
  
  &__container {
    font-weight: 600;
    overflow: hidden;
    height: 6.5cqw;
    padding: 0px 2cqw;

    &:before {
      content: '[';
      left: 0;
      padding-left: 3cqw;
      padding-right: 10%;
    }

    &:after {
      content: ']';
      position: absolute;
      right: 0;
    }

    &:after, &:before {
      position: absolute;
      top: 0;
      
      //color: #55DC99;
      color: #95d5f6;
      font-size: 11cqw;
      line-height: 5.5cqw;
      
      -webkit-animation-name: opacity;
      -webkit-animation-duration: 2s;
      -webkit-animation-iteration-count: infinite;
      animation-name: opacity;
      animation-duration: 2s;
      animation-iteration-count: infinite;
    }

    &__text {
      display: inline;
      float: left;
      margin: 0;
      margin-left: 8%;
    }

    &__list {
      margin-top: 0;
      margin-left: -4%;
      padding-left: 45cqw;
      text-align: center;
      list-style: none;
      
      -webkit-animation-name: change;
      -webkit-animation-duration: 10s;
      -webkit-animation-iteration-count: infinite;
      animation-name: change;
      animation-duration: 10s;
      animation-iteration-count: infinite;

      &__item {
        line-height: 6.5cqw;
        margin:0;
      }
    }
  }
}

.date_container {
  position: relative;
  top: 40%;
  left: 50%;
  margin: auto;
  margin-top: -1%;
  transform: translate(-25%, -50%);
  height: 50%;
  width:100%;
  overflow:hidden;
  font-family: 'Source Sans 3', sans-serif;
}

.button_container {
  position: relative;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 90%;
  width:100%;
  margin-top: 3.5cqw;
  overflow:hidden;
}

.signup_button_container {
  margin: 0;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.signup_button {
  background-color: transparent;
  //border: 2px solid #B1FF7B;
  border: 2px solid #f5f5f5;
  box-sizing: border-box;
  color: #f9fef8;
  font-family: 'Source Sans 3', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  padding: 5% 10vmax;
  position: relative;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.signup_button:hover,
.signup_button:active {
  outline: 0;
}

.signup_button:hover {
  background-color: transparent;
  cursor: pointer;
}

.signup_button:before {
  //background-color: #55DC99;
  background-color: #499dc6;
  content: "";
  height: calc(100% + 3px);
  position: absolute;
  right: -7px;
  top: -9px;
  transition: background-color 300ms ease-in;
  width: 100%;
  z-index: -1;
}

.signup_button:hover:before {
  //background-color: #60f3aa;
  background-color: #95d5f6;
}



@-webkit-keyframes opacity {
  0%, 100% {opacity:0;}
  50% {opacity:1;}
}

@-webkit-keyframes change {
  0%, 12.66%, 100% {transform:translate3d(0,0,0);}
  16.66%, 29.32% {transform:translate3d(0,-25%,0);}
  33.32%,45.98% {transform:translate3d(0,-50%,0);}
  49.98%,62.64% {transform:translate3d(0,-75%,0);}
  66.64%,79.3% {transform:translate3d(0,-50%,0);}
  83.3%,95.96% {transform:translate3d(0,-25%,0);}
}

@-o-keyframes opacity {
  0%, 100% {opacity:0;}
  50% {opacity:1;}
}

@-o-keyframes change {
  0%, 12.66%, 100% {transform:translate3d(0,0,0);}
  16.66%, 29.32% {transform:translate3d(0,-25%,0);}
  33.32%,45.98% {transform:translate3d(0,-50%,0);}
  49.98%,62.64% {transform:translate3d(0,-75%,0);}
  66.64%,79.3% {transform:translate3d(0,-50%,0);}
  83.3%,95.96% {transform:translate3d(0,-25%,0);}
}

@-moz-keyframes opacity {
  0%, 100% {opacity:0;}
  50% {opacity:1;}
}

@-moz-keyframes change {
  0%, 12.66%, 100% {transform:translate3d(0,0,0);}
  16.66%, 29.32% {transform:translate3d(0,-25%,0);}
  33.32%,45.98% {transform:translate3d(0,-50%,0);}
  49.98%,62.64% {transform:translate3d(0,-75%,0);}
  66.64%,79.3% {transform:translate3d(0,-50%,0);}
  83.3%,95.96% {transform:translate3d(0,-25%,0);}
}

@keyframes opacity {
  0%, 100% {opacity:0;}
  50% {opacity:1;}
}

@keyframes change {
  0%, 12.66%, 100% {transform:translate3d(0,0,0);}
  16.66%, 29.32% {transform:translate3d(0,-25%,0);}
  33.32%,45.98% {transform:translate3d(0,-50%,0);}
  49.98%,62.64% {transform:translate3d(0,-75%,0);}
  66.64%,79.3% {transform:translate3d(0,-50%,0);}
  83.3%,95.96% {transform:translate3d(0,-25%,0);}
}
