.section-title {
  color: #fff;
  font-family: Major Mono Display,sans-serif;
  font-weight: 800;
  margin-bottom: 1em;
  text-align: center;
}

@mixin main-colors {
    background-color: #016098 !important;
    color: white !important;
  }
  
  .accordion-button {
    @include main-colors;
    background-color: #016098 !important;
    font-style: bold;
    // outline: solid white 1px;
    // border: solid white 10px;
  }
  
  .accordion-item {
    @include main-colors;
    background-color: #016098 !important;
    font-style: italic;
    border: solid white 0.2px;
    outline: solid white 0.2px;
  }
  
  .faq {
    padding: 2.5em;
    border-radius: 10px;
    border-radius: 50px;
    background: #016098 !important;
  }
  
  .accordion-button:after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  }