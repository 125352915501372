@import 'https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@300;400&display=swap';

.container {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-color: #0407454e;
}

.about-header {
    color: #fff;
    font-family: Major Mono Display,sans-serif;
    font-weight: 800;
    font-size: 4rem;
}




