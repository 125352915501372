@import 'https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@200&display=swap';
@import 'https://fonts.googleapis.com/css2?family=Kode+Mono:wght@400..700&display=swap';
@import '~bootstrap/scss/bootstrap';

* {
    box-sizing :border-box;
}

body {
    margin: 0;
}

.nav-bar {
    //background-color: #55DC99;
    background-color: #016098;
    border-bottom: 3px solid #f5f5f5;
    z-index: 1000;
}

.nav-title {
    display: flex;
    margin: 0;
    align-items:center;
}

.nav-title h1{
    font-family: "Kode Mono", monospace;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 5cqw;
    text-decoration: none;
    display: inline;
    white-space: nowrap;
    color: #f5f5f5;
}

.nav-logo {
    width: 40%;
    height: 40%;
    top: 50%;
    display: inline;
    vertical-align:middle
}
 
.nav-toggle {
    border-color: rgba(255, 255, 255, 0.686);
    color: rgba(255, 255, 255, 0.8);
    display: inline-block;
    position: absolute;
    top: 3em;
    right:5%;
    z-index: 0;
}

.nav-toggle:hover {
    transition: 0.5s;
    border-color: rgba(255, 255, 255, 0.8);
    color: rgba(255, 255, 255, 0.8);
    z-index: 0;
}

.header-link {
    text-decoration: none;
    color: #ffffff7e;
    font-size: 20px;
    font-family: "Kode Mono", monospace;
    display: block;
}

.modelOne .header-link {
    font-size: 30px;
}

.container-fluid li {
    list-style: none;
    padding-left:30px;
    padding-right:30px;
    z-index: 0;
}

.container-fluid li.active a{
    color: #fff;
}


.header-link:hover {
    transition: 0.5s;
    color: #ffffffdb;
}

